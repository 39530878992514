import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer({ contactRef }) {
  const toContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="bottom-container">
        <div className="base">
          <dl className="block-table03">
            <div className="wrp-img">
              <img
                src="https://static.wixstatic.com/media/149b90_a0bc039c52f94db0b0dc7097666ce2b0~mv2.png/v1/crop/x_0,y_0,w_441,h_396/fill/w_87,h_77,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/%E3%82%A2%E3%82%BB%E3%83%83%E3%83%88%201_4x_edited.png"
                alt=""
              />
              <h2>UMYCO DESIGN</h2>
            </div>
            <div className="wrp-navi">
              <div>
                <ul className="wrp-txt">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-29">
                    <img
                      alt="Black Instagram Icon"
                      fetchpriority="high"
                      src="https://static.wixstatic.com/media/01c3aff52f2a4dffa526d7a9843d46ea.png/v1/fill/w_41,h_41,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/01c3aff52f2a4dffa526d7a9843d46ea.png"
                    />
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-30">
                    <img
                      alt="黒のYouTubeアイコン"
                      fetchpriority="high"
                      src="https://static.wixstatic.com/media/78aa2057f0cb42fbbaffcbc36280a64a.png/v1/fill/w_41,h_41,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/78aa2057f0cb42fbbaffcbc36280a64a.png"
                    />
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-642">
                    <img
                      alt="Black Facebook Icon"
                      fetchpriority="high"
                      src="https://static.wixstatic.com/media/0fdef751204647a3bbd7eaa2827ed4f9.png/v1/fill/w_41,h_41,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0fdef751204647a3bbd7eaa2827ed4f9.png"
                    />
                  </li>
                  <li>
                    <img
                      alt="ブラックPinterestのアイコン"
                      fetchpriority="high"
                      src="https://static.wixstatic.com/media/8f6f59264a094af0b46e9f6c77dff83e.png/v1/fill/w_41,h_41,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/8f6f59264a094af0b46e9f6c77dff83e.png"
                    />
                  </li>
                </ul>
                <div className="contact_main" onClick={toContact}>
                  <Link to="/">お問い合わせ</Link>
                </div>
              </div>
            </div>
          </dl>

          <div className="foot_bot_txt" style={{ padding: "0 0 2% 26%" }}>
            <p className="first__txt">
              事務所所在地：1309 Coffeen Avenue STE 1200，Sheridan, Wyoming
              82801
            </p>
            <p className="first__txt">E-mail : area@uponid.shop</p>
          </div>
          <p className="design_p">© 2019 Umyco Design All Rights Reserved</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
